<template>
  <section>
    <label class="mb-0">{{ metadata.label }}</label>
    <b-row class="mb-3">
      <b-col
        v-if="!metadata.operation || ignoreCaseAllowed || ignoreAccentsAllowed"
        cols="12"
        md="6"
      >
        <FilterOperationInputComponent
          v-if="!metadata.operation"
          :value="value.operation"
          :dataType="metadata.type"
          :operations="metadata.operations"
          @input="(code) => $emit('operationChanged', code)"
        ></FilterOperationInputComponent>
        <b-form-checkbox
          v-if="ignoreCaseAllowed"
          switch
          :checked="ignoreCaseValue"
          @input="(checked) => $emit('ignoreCaseChanged', checked)"
        >
          Ignorer la casse
        </b-form-checkbox>
        <b-form-checkbox
          v-if="ignoreAccentsAllowed"
          switch
          :checked="ignoreAccentsValue"
          @input="(checked) => $emit('ignoreAccentsChanged', checked)"
        >
          Ignorer les accents
        </b-form-checkbox>
      </b-col>
      <b-col cols="12" md="6">
        <FilterOperandsInputComponent
          :value="value.operands"
          :dataType="metadata.type"
          :minOperands="minOperands"
          :maxOperands="maxOperands"
          :options="computedOptions"
          @input="(list) => $emit('operandsChanged', list)"
        ></FilterOperandsInputComponent>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import FilterOperationInputComponent from "./FilterOperationInputComponent.vue";
import FilterOperandsInputComponent from "./FilterOperandsInputComponent.vue";
import UtilsService from "../../services/utils.service";
export default {
  name: "FilterInputGroup",
  components: { FilterOperationInputComponent, FilterOperandsInputComponent },
  data: () => ({
    options: null,
  }),
  props: {
    // Valeur du filtre (format { property: "", operation: "", ignoreCase: false, operands: [] })
    value: { type: Object, required: true },
    // Objet metadata du filtre (requis)
    metadata: { type: Object, required: true },
  },
  computed: {
    operation() {
      return this.$store.getters["tab/operation"](this.value.operation);
    },
    minOperands() {
      return this.operation?.minOperands;
    },
    maxOperands() {
      return this.operation?.maxOperands;
    },
    computedOptions() {
      return this.operation?.enumerable === false ? null : this.options;
    },
    ignoreCaseAllowed() {
      return (
        this.metadata.type === "text" &&
        this.metadata.ignoreCase == null &&
        this.value.operation &&
        this.value.operands.length > 0 &&
        this.computedOptions == null
      );
    },
    ignoreCaseValue() {
      return (
        this.metadata.ignoreCase === true || this.value.ignoreCase === true
      );
    },
    ignoreAccentsAllowed() {
      return (
        this.metadata.type === "text" &&
        this.metadata.ignoreAccents == null &&
        this.value.operation &&
        this.value.operands.length > 0 &&
        this.computedOptions == null
      );
    },
    ignoreAccentsValue() {
      return (
        this.metadata.ignoreAccents === true ||
        this.value.ignoreAccents === true
      );
    },
  },
  async mounted() {
    this.$watch(
      () => this.value.operation,
      () => this.operationChanged()
    );
    if (this.metadata.enumerator) {
      let enumeration = await this.metadata.enumerator(this.$store);
      this.options = enumeration.map((e) =>
        typeof e === "object" ? e : { value: e, text: String(e) }
      );
    }
  },
  methods: {
    operationChanged() {
      // Si l'opération est nulle, on vide les opérandes
      if (!this.operation) {
        this.$emit("operandsChanged", []);
        return;
      }

      // S'il y a trop peu d'opérandes, on en rajoute
      if (this.value.operands.length < this.operation.minOperands) {
        let operands = UtilsService.deepCopy(this.value.operands);
        while (operands.length < this.operation.minOperands) {
          operands.push(null);
        }
        this.$emit("operandsChanged", operands);
      }

      // S'il y a trop d'opérandes, on en supprime
      if (this.value.operands.length > this.operation.maxOperands) {
        this.$emit(
          "operandsChanged",
          this.value.operands.slice(0, this.operation.maxOperands)
        );
      }
    },
  },
};
</script>
