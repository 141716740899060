<template>
  <section>
    <b-row v-for="(operand, index) in computedValue" :key="index">
      <b-col>
        <b-form-select
          v-if="options"
          :options="options"
          :value="operand"
          @input="(value) => onInput(index, value)"
        ></b-form-select>
        <b-form-input
          v-else
          ref="input"
          :type="computedDataType"
          :value="operand"
          @input="(value) => onInput(index, value)"
        ></b-form-input>
      </b-col>
      <b-col v-if="removeOperandAllowed" cols="auto" class="pl-0">
        <b-button pill @click="removeOperand(index)">
          <b-icon-trash></b-icon-trash>
        </b-button>
      </b-col>
    </b-row>
    <b-button v-if="addOperandAllowed" pill block @click="addOperand">
      <b-icon-plus-circle></b-icon-plus-circle>
    </b-button>
  </section>
</template>

<script>
import UtilsService from "../../services/utils.service";
export default {
  name: "FilterOperandsInputComponent",
  props: {
    // Tableau des opérandes
    value: Array,
    // Type de données des opérandes ("text" par défaut)
    dataType: {
      type: String,
      default: "text",
    },
    // Nombre d'opérandes minimum (1 par défaut)
    minOperands: {
      type: Number,
      default: 0,
    },
    // Nombre d'opérandes maximum (1 par défaut)
    maxOperands: {
      type: Number,
      default: 0,
    },
    // Liste des options autorisées (pour select plutôt qu'input)
    options: Array,
  },
  computed: {
    computedValue() {
      return this.dataType === "datetime"
        ? this.value.map((o) => o?.slice(0, 10))
        : this.value;
    },
    computedDataType() {
      return this.dataType === "datetime" ? "date" : this.dataType;
    },
    removeOperandAllowed() {
      return this.value.length > this.minOperands;
    },
    addOperandAllowed() {
      return this.value.length < this.maxOperands;
    },
  },
  methods: {
    onInput(index, value) {
      let operands = UtilsService.deepCopy(this.value);
      if (this.dataType === "datetime" && value) {
        operands[index] = value.slice(0, 10) + "T00:00:00.000Z";
      } else {
        operands[index] = value;
      }
      this.$emit("input", operands);
    },
    removeOperand(index) {
      let operands = UtilsService.deepCopy(this.value);
      operands.splice(index, 1);
      this.$emit("input", operands);
    },
    addOperand() {
      let operands = UtilsService.deepCopy(this.value);
      this.$emit("input", [...operands, null]);
    },
  },
};
</script>
