<template>
  <b-button
    :variant="activeFilters > 0 ? 'warning' : 'primary'"
    @click="filtrer"
  >
    <b-icon-funnel></b-icon-funnel>
    <span class="d-none d-md-inline"> Filtres</span>
    <span v-if="activeFilters > 0"> ({{ activeFilters }})</span>
    <FiltersDialog ref="filtersDialog"></FiltersDialog>
  </b-button>
</template>

<script>
import FiltersDialog from "./FiltersDialog.vue";
export default {
  name: "FiltersButtonComponent",
  components: { FiltersDialog },
  /**
   * tableKey = clé d'identification du tableau dans le store
   */
  props: ["tableKey"],
  computed: {
    activeFilters() {
      return this.$store.getters["tab/activeFilters"](this.tableKey);
    },
  },
  methods: {
    async filtrer() {
      let rechercher = await this.$refs.filtersDialog.show({
        tableKey: this.tableKey,
      });
      if (rechercher) {
        this.$emit("rechercher");
      }
    },
  },
};
</script>
