<template>
  <b-modal ref="modal" scrollable @close="close">
    <template #modal-title>Tris</template>
    <section v-if="pageable">
      <SortInputGroup
        v-for="(property, index) in pageable.sortBy"
        :key="index"
        :properties="properties"
        :sortBy="pageable.sortBy[index]"
        :sortDesc="pageable.sortDesc[index]"
        @sortPropertyChanged="(value) => (pageable.sortBy[index] = value)"
        @sortDirectionChanged="(value) => (pageable.sortDesc[index] = value)"
      ></SortInputGroup>
      <b-button-toolbar class="justify-content-center">
        <b-button-group>
          <b-button
            class="rounded-left"
            variant="outline-primary"
            :disabled="pageable.sortBy.length <= 1"
            @click="removeSort"
          >
            <b-icon-trash-fill></b-icon-trash-fill>
            <span class="d-none d-md-inline">&nbsp; Retirer un tri</span>
          </b-button>
          <b-button class="rounded-right" variant="primary" @click="addSort">
            <b-icon-plus-circle-fill></b-icon-plus-circle-fill>
            <span class="d-none d-md-inline">&nbsp; Ajouter un tri</span>
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </section>
    <template #modal-footer>
      <b-container fluid>
        <b-row align-h="between">
          <b-col>
            <b-button block pill variant="outline-primary" @click="reset">
              R&eacute;initialiser
            </b-button>
          </b-col>
          <b-col>
            <b-button block pill variant="primary" @click="search">
              Rechercher
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-modal>
</template>

<script>
import SortInputGroup from "./SortInputGroup.vue";
import UtilsService from "../../services/utils.service";
export default {
  name: "SortsDialog",
  components: { SortInputGroup },
  data: () => ({
    tableKey: null,
    fields: null,
    pageable: null,
    resolvePromise: null,
    rejectPromise: null,
  }),
  computed: {
    properties() {
      return (
        this.fields
          ?.filter((f) => f.sortable === true)
          .map((f) => ({ value: f.key, text: f.label })) ?? []
      );
    },
  },
  methods: {
    show(options) {
      this.tableKey = options?.tableKey;
      this.fields = this.$store.getters["tab/fields"](this.tableKey);
      this.pageable = UtilsService.deepCopy(
        this.$store.getters["tab/pageable"](this.tableKey)
      );
      this.$refs["modal"].show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    addSort() {
      this.pageable.sortBy.push("");
      this.pageable.sortDesc.push(false);
    },
    removeSort() {
      this.pageable.sortBy.pop();
      this.pageable.sortDesc.pop();
    },
    close() {
      this.$refs["modal"].hide();
      this.resolvePromise(false);
    },
    reset() {
      this.$refs["modal"].hide();
      this.$store.dispatch("tab/sortsReset", this.tableKey);
      this.resolvePromise(true);
    },
    search() {
      this.$refs["modal"].hide();
      this.$store.dispatch("tab/pageableChanged", {
        key: this.tableKey,
        pageable: this.pageable,
      });
      this.resolvePromise(true);
    },
  },
};
</script>
