<template>
  <b-button-group>
    <slot name="before"></slot>
    <FiltersButtonComponent
      :class="classFilters"
      :tableKey="tableKey"
      @rechercher="$emit('rechercher')"
    ></FiltersButtonComponent>
    <SortsButtonComponent
      :class="classSorts"
      :tableKey="tableKey"
      @rechercher="$emit('rechercher')"
    ></SortsButtonComponent>
    <slot name="after"></slot>
  </b-button-group>
</template>

<script>
import FiltersButtonComponent from "../filters/FiltersButtonComponent.vue";
import SortsButtonComponent from "../sorts/SortsButtonComponent.vue";
export default {
  name: "TableButtonGroup",
  components: { FiltersButtonComponent, SortsButtonComponent },
  props: {
    // Clé d'identification du tableau dans le store
    tableKey: {
      type: String,
      required: true,
    },
  },
  computed: {
    classFilters() {
      if (this.$slots.before) {
        return "";
      }
      return "rounded-left";
    },
    classSorts() {
      if (this.$slots.after) {
        return "";
      }
      return "rounded-right";
    },
  },
};
</script>
