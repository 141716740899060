<template>
  <b-row class="mb-3">
    <b-col cols="12" md="6">
      <b-form-select
        :options="properties"
        :value="sortBy"
        @input="(value) => $emit('sortPropertyChanged', value)"
      ></b-form-select>
    </b-col>
    <b-col cols="12" md="6">
      <b-form-radio-group
        stacked
        :options="directions"
        :checked="sortDesc"
        @input="(value) => $emit('sortDirectionChanged', value)"
      ></b-form-radio-group>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "SortInputGroup",
  props: {
    // Propriétés possibles
    properties: { type: Array, required: true },
    // Propriété de tri initiale
    sortBy: { type: String, required: true },
    // Direction de tri initiale
    sortDesc: { type: Boolean, default: false },
  },
  computed: {
    directions() {
      return [
        { value: false, text: "Tri croissant" },
        { value: true, text: "Tri décroissant" },
      ];
    },
  },
};
</script>
