<template>
  <b-button :variant="activeSorts > 0 ? 'warning' : 'primary'" @click="trier">
    <b-icon-sort-down></b-icon-sort-down>
    <span class="d-none d-md-inline"> Tris</span>
    <span v-if="activeSorts > 0"> ({{ activeSorts }})</span>
    <SortsDialog ref="sortsDialog"></SortsDialog>
  </b-button>
</template>

<script>
import SortsDialog from "./SortsDialog.vue";
export default {
  name: "SortsButtonComponent",
  components: { SortsDialog },
  /**
   * tableKey = clé d'identification du tableau dans le store
   */
  props: ["tableKey"],
  computed: {
    activeSorts() {
      return this.$store.getters["tab/activeSorts"](this.tableKey);
    },
  },
  methods: {
    async trier() {
      let rechercher = await this.$refs.sortsDialog.show({
        tableKey: this.tableKey,
      });
      if (rechercher) {
        this.$emit("rechercher");
      }
    },
  },
};
</script>
