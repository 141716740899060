import UtilsService, { SortDirection } from "./utils.service";

// Fonctions réutilisables pour les composants incluant des PaginatedTable

/**
 * Pour utiliser ce code, le composant qui implémente le tableau doit : 
 * - définir ses data comme suit { ...<dataFactory de ce module>(<TableKey du tableau>), autresData }
 * - définir le lifecycle hook created et y appeler <created de ce module>() sur this
 * - définir ses computed comme suit { ...<computed de ce module>, autresComputed }
 * - définir ses methods comme suit { ...<methods de ce module>, autresMethods }
 */

// DATA

const dataFactory = function (key) {
  return {
    tableKey: key,
    fields: null,
    busy: false,
  };
};

// LIFECYCLE HOOKS

const created = function () {
  this.fields = this.$store.getters["tab/fields"](this.tableKey);
};

// COMPUTED PROPERTIES

/**
 * Getter à utiliser pour récupérer le pageable
 */
const pageable = function () {
  return UtilsService.deepCopy(this.$store.getters["tab/pageable"](this.tableKey));
};

/**
 * Getter à utiliser pour récupérer les propriétés de tri
 */
const sortProperties = function () {
  if (!this.pageable.sortBy) {
    return [];
  }
  let properties = Array.isArray(this.pageable.sortBy)
    ? this.pageable.sortBy
    : [this.pageable.sortBy];
  return properties.map((s) => this.fields.find((f) => f.key === s));
};

/**
 * Getter à utiliser pour récupérer les directions de tri
 */
const sortDirections = function () {
  let directions = Array.isArray(this.pageable.sortDesc)
    ? [...this.pageable.sortDesc]
    : [this.pageable.sortDesc];
  for (let i = 0; i < this.sortProperties.length; i++) {
    directions[i] = directions[i] === true
      ? SortDirection.DESC
      : SortDirection.ASC;
  }
  return directions.slice(0, this.sortProperties.length);
};

// METHODS

/**
 * A appeler pour déclencher une nouvelle recherche (page 1)
 */
const rechercher = async function () {
  this.$store.dispatch("tab/pageableChanged", {
    key: this.tableKey,
    pageable: { ...this.pageable, currentPage: 1, },
  });
  // Rafraichissement des computed properties avant rechargement des données
  await this.$nextTick();
  this.load();
};

/**
 * A appeler lorsque la pagination a été modifiée pour mettre à jour le store
 * 
 * @param {*} pageable 
 */
const pageableChanged = async function (pageable) {
  this.$store.dispatch("tab/pageableChanged", { key: this.tableKey, pageable });
  // Rafraichissement des computed properties avant rechargement des données
  await this.$nextTick();
  this.load();
};

/**
 * A appeler pour construire la fonction de filtrage finale (pour usage du store)
 */
const getFilterFunction = function () {
  let metadata = this.$store.getters["tab/filtersMetadata"](this.tableKey);

  // Récupération des filtres custom
  let customFilters = this.$store.getters["tab/customFilters"](this.tableKey)
    .filter(f => f.operands.reduce((acc, next) => acc && next != null && next !== "", true));
  let customFunction = (value) => customFilters.reduce((acc, next) => {
    let filterMetadata = metadata.find(m => m.property === next.property);
    return acc && filterMetadata.filterFunction(value, next, filterMetadata);
  }, true);

  // Récupération des filtres standards
  let standardFilters = this.$store.getters["tab/standardFilters"](this.tableKey)
    .filter(f => !!f.operation && f.operands.reduce((acc, next) => acc && next != null && next !== "", true));
  let standardFunction = UtilsService.filterBy({ operation: "$and", operands: standardFilters, });

  // Transformation en fonction de prédicat (value) => Boolean
  return (value) => customFunction(value) && standardFunction(value);
};

/**
 * A appeler pour construire la fonction de tri finale (pour usage du store)
 */
const getSortFunction = function () {
  let sortFunctions = [];
  for (let i = 0; i < this.sortProperties.length; i++) {
    if (this.sortProperties[i].sortFunction) {
      // Soit on a une fonction de tri spécifique à ce champ
      sortFunctions.push(this.sortProperties[i].sortFunction(this.sortDirections[i]))
    } else {
      // Soit on construit la fonction de tri par défaut
      sortFunctions.push(UtilsService.sortByStringProperty(
        this.sortProperties[i].sortBy ?? this.sortProperties[i].key,
        this.sortDirections[i]
      ));
    }
  }
  return UtilsService.sortBy(...sortFunctions);
};

/**
 * A appeler pour construire le payload de recherche (pour usage de l'API)
 */
const getSearchPayload = function () {
  let payload = { customFilters: {} };

  // Ajout des filtres custom
  let customFilters = this.$store.getters["tab/customFilters"](this.tableKey);
  customFilters.forEach(f => {
    payload.customFilters[f.property] = f.operands.length === 1 ? f.operands[0] : f.operands;
  });

  // Ajout des filtres standards
  let standardFilters = this.$store.getters["tab/standardFilters"](this.tableKey)
    .filter(f => !!f.operation && f.operands.reduce((acc, next) => acc && next != null && next !== "", true));
  payload.genericFilters = { operation: "$and", operands: standardFilters, };

  // Ajout du pageable
  payload.pageable = {
    numPage: this.pageable.currentPage - 1,
    pageSize: this.pageable.perPage,
    sortProperties: this.sortProperties.map(p => p.sortBy ?? p.key),
    sortDirections: this.sortDirections.map(d => d === SortDirection.DESC ? "DESC" : "ASC"),
  }

  return payload;
};

const computed = {
  pageable,
  sortProperties,
  sortDirections,
};

const methods = {
  rechercher,
  pageableChanged,
  getFilterFunction,
  getSortFunction,
  getSearchPayload,
};

export { dataFactory, created, computed, methods };