<template>
  <b-modal ref="modal" scrollable @close="close">
    <template #modal-title>Filtres</template>
    <section v-if="filters">
      <FilterInputGroup
        v-for="(m, index) in metadata"
        :key="index"
        :value="filters[index]"
        :metadata="m"
        @operationChanged="(code) => (filters[index].operation = code)"
        @ignoreCaseChanged="(checked) => (filters[index].ignoreCase = checked)"
        @ignoreAccentsChanged="
          (checked) => (filters[index].ignoreAccents = checked)
        "
        @operandsChanged="(list) => (filters[index].operands = list)"
      ></FilterInputGroup>
    </section>
    <template #modal-footer>
      <b-container fluid>
        <b-row align-h="between">
          <b-col>
            <b-button block pill variant="outline-primary" @click="reset">
              R&eacute;initialiser
            </b-button>
          </b-col>
          <b-col>
            <b-button block pill variant="primary" @click="search">
              Rechercher
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-modal>
</template>

<script>
import FilterInputGroup from "./FilterInputGroup.vue";
import UtilsService from "../../services/utils.service";
export default {
  name: "FiltersDialog",
  components: { FilterInputGroup },
  data: () => ({
    tableKey: null,
    metadata: null,
    filters: null,
    resolvePromise: null,
    rejectPromise: null,
  }),
  methods: {
    show(options) {
      this.tableKey = options?.tableKey;
      this.metadata = this.$store.getters["tab/filtersMetadata"](this.tableKey);
      this.filters = UtilsService.deepCopy(
        this.$store.getters["tab/filters"](this.tableKey)
      );
      this.$refs["modal"].show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    close() {
      this.$refs["modal"].hide();
      this.resolvePromise(false);
    },
    reset() {
      this.$refs["modal"].hide();
      this.$store.dispatch("tab/filtersReset", this.tableKey);
      this.resolvePromise(true);
    },
    search() {
      this.$refs["modal"].hide();
      this.$store.dispatch("tab/filtersChanged", {
        key: this.tableKey,
        filters: this.filters,
      });
      this.resolvePromise(true);
    },
  },
};
</script>
