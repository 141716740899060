<template>
  <b-form-select
    :options="options"
    :disabled="computedDisabled"
    :value="value"
    @input="(value) => $emit('input', value)"
    @change="(value) => $emit('change', value)"
  ></b-form-select>
</template>

<script>
export default {
  name: "FilterOperationInputComponent",
  props: {
    // Code de l'opération sélectionnée
    value: undefined,
    // Type de données des opérandes ("text" par défaut)
    dataType: {
      type: String,
      default: "text",
    },
    // Codes des opérations autorisées (toutes par défaut)
    operations: {
      type: Array,
      default: () => [],
    },
    // true pour griser le controle (false par défaut)
    disabled: [Boolean, String],
  },
  computed: {
    operationsFromType() {
      // Récupérer les opérations autorisées sur ce type de données
      return this.$store.getters["tab/operationsFromType"](this.dataType);
    },
    allowedOperations() {
      // S'il n'y a pas de filtrage sur les opérations
      // On renvoie la liste correspondant au type de donnée
      if ((this.operations ?? []).length <= 0) {
        return this.operationsFromType;
      }

      // Sinon on restreint la liste du type de donnée
      return this.operations
        .map((o) => (typeof o === "object" ? o : { code: o, label: null }))
        .map((o) => {
          // Récupérer l'opération correspondante dans le store
          let operation = this.operationsFromType.find(
            (x) => x.code === o.code
          );

          // Filtrer les éventuelles opérations non autorisées (erreurs de paramétrage)
          if (operation == null) {
            return null;
          }

          // Si pas de label personnalisé, récupérer celui par défaut
          if (!o.label) {
            o.label = operation.label;
          }
          return o;
        })
        .filter((o) => !!o);
    },
    options() {
      // Transformer les opérations autorisées en options
      let options = this.allowedOperations.map((o) => ({
        value: o.code,
        text: o.label,
      }));
      // Y ajouter l'option nulle (désactive le filtre)
      return [{ value: null, text: "" }, ...options];
    },
    computedDisabled() {
      return this.disabled === true || this.disabled === "true";
    },
  },
};
</script>
